<template>
  <form-validation @submit="submitForm">
    <b-row>
      <b-col sm="12" md="6">
        <my-card :isloading="cardloading" :title="code">
          <template slot="body">
            <b-row>
              <b-col v-if="$route.params.id">
                <table class="w-100 mb-2">
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Di Input Oleh :</td>
                      <td class="text-right">{{inputInfo.user_create}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Di Update Oleh :</td>
                      <td class="text-right">{{inputInfo.user_update}}</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col sm="12">
                <b-form-group label-for="radio-group-type" label="Tipe">
                  <b-form-radio-group
                  disabled
                  id="radio-group-type"
                  v-model="type"
                  :options="utils.types"
                  text-field="label"
                  >
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col sm="12" v-show="type==1">
                <form-select disabled ref="branch2Select" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" :value="id_branch_sender" label="Cabang Tujuan"></form-select>
              </b-col>
              <b-col sm="12" v-show="type==2">
                <form-select disabled ref="supplierSelect" url="v1/supplier_select2" :value="id_supplier" label="Pemasok"></form-select>
              </b-col>
              <b-col sm="12">
                <form-input disabled :rules="{required: false}" :value="request_code" label="Kode Permintaan"></form-input>
              </b-col>
              <b-col sm="12">
                <form-date disabled :rules="{required: true}" :value="request_date" label="Tgl Permintaan"></form-date>
              </b-col>
              <b-col sm="12">
                <form-textarea disabled :rules="{required: false}" :value="request_note" label="Catatan Permintaan"></form-textarea>
              </b-col>
              <b-col sm="12">
                <form-input disabled :rules="{required: true}" :value="p_or_t_code" :label="`Kode ${type==1 ? 'Transfer':'Pembelian'}`"></form-input>
              </b-col>
              <b-col sm="12">
                <form-date disabled :rules="{required: true}" :value="p_or_t_date" :label="`Tgl ${type==1 ? 'Transfer':'Pembelian'}`"></form-date>
              </b-col>
              <b-col sm="12">
                <form-textarea disabled :rules="{required: false}" :value="p_or_t_note" :label="`Catatan ${type==1 ? 'Transfer':'Pembelian'}`"></form-textarea>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
      <b-col sm="12" md="6">
        <my-card :isloading="cardloading">
          <template slot="body">
            <b-row>
              <b-col sm="12">
                <form-select :disabled="formStatus!=0 || !isPusat" ref="branchSelect" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" @value-changed="branch_name = $event.label" :rules="{required: true}" v-model="id_branch" label="Cabang"></form-select>
              </b-col>
              <b-col sm="12">
                <form-date :disabled="!cP(93)||formStatus==5" :rules="{required: true}" v-model="date" label="Tanggal"></form-date>
              </b-col>
              <b-col sm="12">
                <form-input :disabled="formStatus==5" :rules="{required: false}" v-model="delivery_no" label="No Surat Jalan"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :disabled="formStatus==5" :rules="{required: false}" v-model="receiver" label="Nama Penerima"></form-input>
              </b-col>
              <b-col sm="12">
                <form-textarea :disabled="formStatus==5" :rules="{required: false}" v-model="note" label="Catatan Penerimaan"></form-textarea>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
      <b-col sm="12">
        <my-card :isloading="cardloading" title="Detail Item">
          <template slot="body">
            <div>
              <b-row v-for="(tr,i) in details" :key="i">
                <b-col sm="12" md="2">
                  <form-select disabled size="sm" :ref="`itemSelect-${i}`" url="v1/item_select2" @value-changed="rowItemChange($event,i)" :rules="{required: true}" v-model="tr.id_item" :label="`Item ke ${i+1}`"></form-select>
                </b-col>
                <b-col sm="4" md="2">
                  <form-input-number input-group :append="tr.name_unit_origin" :rules="{ required: true }" disabled size="sm" v-model="tr.qty_p_or_t" :label="`Qty ${type==1 ? 'Transfer':'Pembelian'}`"></form-input-number>
                </b-col>
                <b-col sm="6" md="2">
                  <form-input-number :rules="{ required: true }" disabled size="sm" v-model="tr.qty_in" label="Qty yg sudah diterima"></form-input-number>
                </b-col>
                <b-col sm="6" md="2">
                  <form-select size="sm" :disabled="[1,5].includes(formStatus)" :ref="`unitSelect-${i}`" url="v1/multi_unit_select2" @ajax-result="currentUnitOptions=$event" :queryparams="{id_item: tr.id_item}" @value-changed="rowUnitChange($event,i)" :rules="{required: true}" v-model="tr.id_unit" label="Stn"></form-select>
                </b-col>
                <b-col sm="6" md="1">
                  <form-input-number :disabled="formStatus==5" :rules="{ required: true, minimal:[0] }" size="sm" v-model="tr.qty" label="Qty"></form-input-number>
                </b-col>
                <b-col sm="12" md="3">
                  <form-input :disabled="formStatus==5" size="sm" v-model="tr.note" label="Catatan"></form-input>
                </b-col>
                <b-col sm="12">
                  <hr>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col sm="12" offset-md="9" md="3">
                <b-row>
                  <b-col sm="12">
                    <form-input-number disabled :value="totalQty" label="Total Qty" :rules="{required: true}"></form-input-number>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <!-- <pre>{{details}}</pre> -->
              </b-col>
              <b-col sm="12" class="mt-4" v-if="formStatus!=5">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'receipts'}"></button-back>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
    </b-row>
  </form-validation>
</template>

<script>
import FormTextarea from '@/my-components/FormTextarea.vue'
import FormDate from '@/my-components/FormDate.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import FormInput from '@/my-components/FormInput.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import {BFormRadioGroup,BFormGroup} from 'bootstrap-vue'
import _ from 'lodash'
import utils from './utils'

export default {
  components:{
    FormDate,
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormSelect,
    FormInputNumber,
    FormTextarea,
    BFormRadioGroup,
    BFormGroup
  },
  data(){
    return {
      utils:utils,
      lodash: _,
      isloading: false,
      cardloading: false,
      status: "",
      code: "",
      id: this.$route.params.id||null,
      type: 1,
      id_branch: null,
      branch_name: "",
      id_transfer_out: null,
      id_purchase:null,
      id_recept_before:null,
      id_supplier: null,
      supplier_name: "",
      request_code:"",
      request_date: this.$moment().format(),
      request_note:"",
      p_or_t_code: "",
      p_or_t_note: "",
      p_or_t_date: this.$moment().format(),
      date: this.$moment().format(),
      id_branch_sender: null,
      branch_sender_name: "",
      delivery_no: "",
      receiver: "",
      total: 0,
      note: "",
      details : [],
      currentUnitOptions:[],
      inputInfo : {}
    }
  },
  computed:{
    totalQty(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        total += parseFloat(e.qty)
      }
      return total
    },
    formStatus(){
      const meta = this.$route.meta
      if(meta.isEdit) return 1
      if(meta.isTransfer) return 2
      if(meta.isPurchase) return 3
      if(meta.isPart) return 4
      if(meta.isShow) return 5
      else return 0
    },
  },
  methods:{
    ...mapActions({
      dispatchStore: 'receipts/store',
      dispatchShow: 'receipts/show',
      dispatchUpdate: 'receipts/update',
    }),
    async getData(){
      this.cardloading = true
      let data = {}
      if([1,4,5].includes(this.formStatus)) {
        // edit
        data = await this.dispatchShow(this.id)
        const {user_create,user_update,user_approval} = data
        this.inputInfo = {user_create,user_update,user_approval}
        this.type = data.type
        this.name_type = data.name_type
        this.status = data.status
        this.code = data.code
        if([1,5].includes(this.formStatus)) this.date = data.date
        /*
        this.p_or_t_code = data.p_or_t_code
        this.p_or_t_date = data.p_or_t_date
        this.p_or_t_note = data.p_or_t_note
        this.request_code = data.request_code
        this.request_date = data.request_date
        this.request_note = data.request_note
        */
        if (data.purchase) {
          this.p_or_t_code = data.purchase.code
          this.p_or_t_date = data.purchase.date
          this.p_or_t_note = data.purchase.note
          if(data.purchase.stock_in_request) {
            this.request_code = data.purchase.stock_in_request.code
            this.request_date = data.purchase.stock_in_request.date
            this.request_note = data.purchase.stock_in_request.note
          }
        } else if (data.transfer_out) {
          this.p_or_t_code = data.transfer_out.code
          this.p_or_t_date = data.transfer_out.date
          this.p_or_t_note = data.transfer_out.note
          if(data.transfer_out.stock_in_request) {
            this.request_code = data.transfer_out.stock_in_request.code
            this.request_date = data.transfer_out.stock_in_request.date
            this.request_note = data.transfer_out.stock_in_request.note
          }
        }
        this.id_branch = data.id_branch
        this.branch_name = data.branch_name
        this.id_supplier = data.id_supplier
        this.supplier_name = data.supplier_name
        this.id_branch_sender = data.id_branch_sender
        this.branch_sender_name = data.branch_sender_name
        this.id_transfer_out = data.id_transfer_out
        this.id_purchase = data.id_purchase
        this.id_transfer_out = data.id_transfer_out
        this.id_recept_before = data.id_recept_before||this.id
        if (this.formStatus==1||this.formStatus==5) {
          this.receiver = data.receiver
          this.delivery_no = data.delivery_no
          this.note = data.note
          this.id_recept_before = data.id_recept_before
        } else {
          this.id_recept_before = this.id
        }
        const details = data.reception_details.map(d => {
          let res = {}
          res.id_transfer_out_detail = d.id_transfer_out_detail
          res.id_purchase_detail = d.id_purchase_detail
          res.id_item = d.id_item
          res.name_item = d.name_item
          res.id_unit = d.id_unit
          if(d.purchase_detail){
            res.id_unit_origin = d.purchase_detail.id_unit
            res.name_unit_origin = d.purchase_detail.name_unit
          } else {
            res.id_unit_origin = d.transfer_out_detail.id_unit
            res.name_unit_origin = d.transfer_out_detail.name_unit
          }
          res.name_unit = d.name_unit
          res.note = d.note
          res.qty_in = d.qty_already_recept.qty_already_recept_def
          res.qty_p_or_t_in_real = d.transfer_out_detail ? d.transfer_out_detail.qty_out_in_real : d.purchase_detail.qty_purchase_in_real
          res.qty_p_or_t = d.transfer_out_detail ? d.transfer_out_detail.qty : d.purchase_detail.qty
          // for (let i = 0; i < d.qty_already_recept.length; i++) {
          //   const e = d.qty_already_recept[i];
          //   res.qty_in+= e.qty_already_recept
          // }
          res.qty_already_recept_in_real = d.qty_already_recept.qty_already_recept_in_real
          const defsQty = d.transfer_out_detail ? d.transfer_out_detail.qty_out_def : d.purchase_detail.qty_purchase_def
          res.qty = this.formStatus==1||this.formStatus==5 ? d.qty : parseFloat(defsQty)-parseFloat(res.qty_in)
          return res
        })
        this.details = details
        // console.log(details)
      } else if(this.formStatus==2){
        // penerimaan baru dari transfer out
        data = await this.$store.dispatch('transferouts/show', this.id)
        this.type = 1
        this.name_type = "Transfer In"
        this.code = data.code
        this.status = data.status
        this.p_or_t_code = data.code
        this.p_or_t_date = data.date
        this.p_or_t_note = data.note
        this.request_code = data.request_code
        this.request_date = data.request_date
        this.id_branch = data.id_branch
        this.branch_name = data.branch_name
        this.id_branch_sender = data.id_branch_sender
        this.branch_sender_name = data.branch_sender_name
        this.id_transfer_out = data.id
        if (data.stock_in_request) {
          this.request_code = data.stock_in_request.code
          this.request_date = data.stock_in_request.date
          this.request_note = data.stock_in_request.note
        }
        const details = data.transfer_out_details.map(d => {
          let res = {}
          res.id_transfer_out_detail = d.id
          res.id_purchase_detail = null
          res.id_item = d.id_item
          res.name_item = d.name_item
          res.id_unit = null
          res.id_unit_origin = d.id_unit
          res.name_unit_origin = d.name_unit
          res.name_unit = ""
          res.note = ""
          res.qty_in = 0
          res.qty_p_or_t = d.qty
          res.qty = 0
          res.qty_p_or_t_in_real = 0
          return res
        })
        this.details = details
      } else {
        // penerimaan baru dari pembelian
        data = await this.$store.dispatch('purchases/show', this.id)
        this.type = 2
        this.name_type = "Pembelian"
        this.code = data.code
        this.status = data.status
        this.p_or_t_code = data.code
        this.p_or_t_date = data.date
        this.p_or_t_note = data.note
        this.request_code = data.request_code
        this.request_date = data.request_date
        this.id_branch = data.id_branch
        this.branch_name = data.branch_name
        this.id_supplier = data.id_supplier
        this.supplier_name = data.supplier_name
        this.id_purchase = data.id
        if (data.stock_in_request) {
          this.request_code = data.stock_in_request.code
          this.request_date = data.stock_in_request.date
          this.request_note = data.stock_in_request.note
        }
        const details = data.purchase_details.map(d => {
          let res = {}
          res.id_transfer_out_detail = null
          res.id_purchase_detail = d.id
          res.id_item = d.id_item
          res.name_item = d.name_item
          res.id_unit = null
          res.id_unit_origin = d.id_unit
          res.name_unit_origin = d.name_unit
          res.name_unit = ""
          res.note = ""
          res.qty_in = 0
          res.qty_p_or_t = d.qty
          res.qty = 0
          res.qty_p_or_t_in_real = 0
          return res
        })
        this.details = details
      }
      if(data.id_branch) this.$refs.branchSelect.initOption([{value:data.id_branch,label:data.branch_name}])
      if(data.id_branch_sender) this.$refs.branch2Select.initOption([{value:data.id_branch_sender,label:data.branch_sender_name}])
      if(data.id_supplier) this.$refs.supplierSelect.initOption([{value:data.id_supplier,label:data.supplier_name}])
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        this.$nextTick(() => {
          this.$refs[`itemSelect-${i}`][0].initOption([{value:e.id_item,label:e.name_item}])
          if(e.id_unit)this.$refs[`unitSelect-${i}`][0].initOption([{value:e.id_unit,label:e.name_unit}])
        })
      }
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,id_transfer_out,id_purchase,id_recept_before,type,name_type,p_or_t_code,p_or_t_date,id_branch,date,branch_name,id_branch_sender,branch_sender_name,note,details,delivery_no,receiver,id_supplier,supplier_name} = this
      let params = {id,id_transfer_out,id_purchase,id_recept_before,type,name_type,p_or_t_code,p_or_t_date,id_branch,date,branch_name,id_branch_sender,branch_sender_name,note,details,delivery_no,receiver,id_supplier,supplier_name}
      params.total = this.totalQty
      if ([2,3,4].includes(this.formStatus)) {
        delete params.id
      }
      if(this.totalQty<=0) {
        this.isloading = false
        return this.toastWarning("Maaf", "Jumlah total Qty yang anda masukkan 0!")
      }
      let detailedit = JSON.parse(JSON.stringify(details))
      detailedit = detailedit.map(d => {
        const res = Object.assign(d,{
          qty_p_or_t: d.qty_p_or_t_in_real
        })
        delete res.qty_p_or_t_in_real
        delete res.qty_already_recept_in_real
        delete res.qty_in
        return res
      })
      params.details = detailedit
      try {
        // console.log(params)
        if(_.includes([2,3,4],this.formStatus)) await this.dispatchStore(params)
        else await this.dispatchUpdate(params)
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'receipts'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
    deleteRow(row){
      this.details.splice(row,1)
    },
    rowItemChange(e,row){
      let tr = this.details[row]
      tr.name_item = e.label
      tr.id_unit = null
    },
    rowUnitChange(e,row){
      let tr = this.details[row]
      tr.name_unit = e.label
      // console.log(tr)
      // const idunit = e.value

      const currentUnits = JSON.parse(JSON.stringify(this.currentUnitOptions))
      // const indexFirst = 0
      const fromUnit = _.find(currentUnits,{'value': tr.id_unit_origin})
      const toUnit = _.find(currentUnits,{'value': tr.id_unit})
      // console.log(fromUnit,toUnit)
      if (tr.qty_already_recept_in_real) {
        const selisih = tr.qty_p_or_t_in_real - tr.qty_already_recept_in_real
        tr.qty = selisih*currentUnits[0].volume_of/toUnit.volume_of
        tr.qty_in = tr.qty_already_recept_in_real/e.volume_of
      } else {
        tr.qty = tr.qty_p_or_t*fromUnit.volume_of/toUnit.volume_of
      }

      // tr.qty = qtyDef
      if([2,3].includes(this.formStatus)) tr.qty_p_or_t_in_real = fromUnit.volume_of*tr.qty_p_or_t
      // console.log(tr)
    }
  },
  created(){
    this.getData()
  }
}
</script>

<style>

</style>